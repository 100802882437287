import React, { Component } from "react";
import { partners } from "../data/data";

class Movement extends Component {
  state = {};
  render() {
    return (
      <div id="movement" className="movement">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-lg-2">
              <div className="sticky-top">
                <h2 className="secondary_title">Elena Movement</h2>
                <div className="movement_about">
                  <p>
                    No matter what problems you are dealing with, we want to
                    help you find a reason to keep living. By calling{" "}
                    <strong>1-800-273-TALK (8255)</strong> you’ll be connected
                    to a skilled, trained counselor at a crisis center in your
                    area, anytime 24/7.
                    <br />
                    <strong>suicidepreventionlifeline.org</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-9 col-lg-10">
              <p>
                When <strong>mental health</strong> is not a viable topic for
                discussion, stigma leads to silence. Without the words to broach
                the seemingly intractable distortions of depression, isolation,
                and mental illness — reaching out can feel impossible.
              </p>
              <p>
                Into this void steps the power of personal narrative, and
                documentary film. Cinema can be tremendous tool in the effort to
                visualize, verbalize, and assimilate the traumas of mental
                illness. That's precisely what ELENA does: create a vocabulary
                that seeks to articulate the suffering — both the suffering of
                those afflicted with mental illness, and the loved ones left in
                suicide's wake, trying to make sense of an incalculable loss.
                The first step towards change is to tell these stories outloud.
                Simply put: to speak up about mental health is to extend a
                lifeline.
              </p>
              <p>
                <strong>
                  ELENA is proud to partner with a number of organizations
                  dedicated to mental health, loss, grief, and healing
                </strong>
                . Mental illness — which can take hold so suddenly, and thrives
                in the shadows — meets its match in our partner organizations'
                treatment resources, tools, and communities of support. In
                Brazil, the country of its release, ELENA sparked a national
                dialogue—shattering stigma by letting the light in. Here is the
                U.S., ELENA is determined to do that same essential work: create
                meaningful and open dialogue on mental health, share resources,
                help those suffering find treatment and solutions, and confront
                the pain of bereavement with compassion and vested community.
              </p>
              <p>
                Please visit our partner organizations to learn more, find the
                help you may need, get involved personally, or simply spread the
                word and champion their work.
              </p>
              <hr />
              <h4>Partners</h4>
              <div className="row partners">
                {partners.map(partner => {
                  return (
                    <React.Fragment key={partner.logo}>
                      <div className="col-3 col-lg-2">
                        <a
                          href={partner.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={require(`../assets/partners/${partner.logo}`)}
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="col-9 col-lg-10 align-self-center">
                        <a
                          href={partner.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p>{partner.text}</p>
                        </a>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Movement;
