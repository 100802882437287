import React, { Component } from "react";

import Hero from "./components/hero";
import Reviews from "./components/reviews";
import Synopsis from "./components/synopsis";
import AvailableOn from "./components/available";
import Press from "./components/press";
import Crew from "./components/crew";
import Movement from "./components/movement";
import Contact from "./components/contact";
import Director from "./components/director";
import Nav from "./components/nav";
import Blog from "./components/blog";
import Insights from "./components/insights";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Nav />
        <Hero />
        <AvailableOn />
        <Synopsis />
        <Reviews />
        <Insights />
        <Press />
        <Crew />
        <Director />
        <Blog />
        <Movement />
        <Contact />
      </div>
    );
  }
}

export default App;
