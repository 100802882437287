import React, { Component } from "react";

class Director extends Component {
  state = {};
  render() {
    return (
      <div id="director" className="director">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-lg-2">
              <h2 className="secondary_title sticky-top">Director</h2>
            </div>
            <div className="col-sm-9 col-lg-10">
              <p>
                <strong>
                  PETRA COSTA: Director, Screenwriter and Producer
                </strong>
              </p>

              <p>
                In Petra Costa’s work the personal and the political are
                inextricably linked. Her latest film, The Edge of Democracy, is
                a Netflix Original documentary that had its premiere at the
                opening night of Sundance Film Festival in 2019. According to
                First Showing, “the footage she shares is truly jaw-dropping”
                and shows “an astounding inside look at the recent political
                shake up in Brazil, examining and showing us directly how
                democracy is collapsing thanks to political power grabs”. It has
                “all the feel of an All The President’s Men-style political
                thriller….. with the sweep of The Godfather”, as the ScreenDaily
                put it. For POV magazine, this “political documentary is like
                none other, a work both intimate and grand in scope”.
              </p>

              <p>
                The Edge of Democracy is the third documentary of a trilogy
                where Petra investigates her family story. The first, Undertow
                Eyes, portrays her grandparents recollections and stories, in a
                personal and existential tale about love and death. It screened
                at the MOMA in 2010 and won best short Film at the Rio de
                Janeiro International Film Festival 2009, best short Film at the
                London International Documentary Festival, and best short tilm
                the 13th Cine Las Americas International Film Festival among
                others.
              </p>

              <p>
                The second of the trilogy, ELENA, her first documentary feature,
                is a mixture of documentary, diary and fever dream, and was the
                most watched documentary in Brazil in 2013. It tells the story
                of two sisters – and as one searches for the other their
                identities begin to blur. The film was called “a cinematic
                dream” by the New York Times, “haunting and unforgettable” by
                the Hollywood Reporter and was defined as a “masterful debut
                that takes nonfiction where it seldom wants to go – away from
                the comforting embrace of fact and into a realm of
                expressionistic possibility” by Indiewire. ELENA premiered at
                IDFA followed by SXSW and Hotdocs won many prizes such as best
                film at Havana Film Festival (2013), best film at DOCSDF, best
                directing, editing, and best film for the popular jury at the
                Brasília Film Festival, and was nominated for Best
                Cinematography at the 2014 Cinema Eye Honors.
              </p>

              <p>
                Beyond this trilogy Petra co-directed Olmo and the Seagull which
                “explores with thrilling boldness a space between fiction and
                nonfiction that I never dreamed possible”, as Joshua
                Oppenheimer, director of The Act of Killing, wrote. It was
                reviewed by Télérama as “a reflection without taboos, full of
                humor and daring, on creation in the broadest sense.” OLMO AND
                THE SEAGULL premiered at Locarno where it won the Young Jury
                Prize. It also won the Best Nordic Dox Award at CPH:DOX, best
                documentary at the Rio Film Festival, best documentary at the
                Cairo Film Festival and best narrative at the RiverRun
                International film festival among others.
              </p>

              <p>
                Petra started her training in theater in Brazil at the age of
                fourteen and later went to the Dramatic Arts School at the
                University of São Paulo. She completed her undergraduate studies
                Summa Cum Laude in Anthropology at Barnard College, Columbia
                University, New York, and completed her masters in Social
                Psychology at the London School of Economics focusing her
                studies on the concept of trauma.
              </p>
              <a
                href="http://petracosta.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-outline-light">
                  Official Website
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Director;
