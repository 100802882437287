import React, { Component } from "react";
import synopsisoverlay from "../assets/synopsisoverlay.png";
class Synopsis extends Component {
  state = {};
  render() {
    return (
      <div id="synopsis" className="synopsis">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-lg-2">
              <h2 className="secondary_title sticky-top">Synopsis</h2>
            </div>
            <div className="col-sm-9 col-lg-10">
              <p>
                Elena, a young Brazilian woman, moves to New York with the same
                dream as her mother: to become a movie actress. She leaves
                behind her childhood spent in hiding during the years of the
                military dictatorship. She also leaves Petra, her seven-year-old
                sister. Two decades later, Petra also becomes an actress and
                heads to New York. At first in search of her destiny, but
                increasingly, in search of Elena, Petra has only a few clues:
                home movies, newspaper clippings, a diary and some letters.
              </p>
              <p>
                Gradually, the features of the two sisters are confused; we no
                longer know one from the other. When Petra finally finds Elena
                in an unexpected place, she has to learn to let her go. ELENA is
                a film about the persistence of those memories, the
                irreversibility of that loss, and the impact the absence of her
                sister had on the seven-year-old girl.
              </p>
              <p>
                <strong>
                  Documentary | 82’ | color | 35mm DCP | Brazil | 2013
                </strong>
              </p>
            </div>
          </div>
        </div>
        <img src={synopsisoverlay} alt="" />
        {/* <div className="shadow" /> */}
      </div>
    );
  }
}

export default Synopsis;
