import React, { Component } from "react";
import { availableOn } from "../data/data";
class AvailableOn extends Component {
  state = {};
  render() {
    return (
      <div id="availableOn" className="availableOn">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="secondary_title">Available On</h2>
            </div>
          </div>
          <div className="row">
            {availableOn.map(item => {
              return (
                <div
                  key={item.logoUrl}
                  className="col-6 col-sm-4 col-lg-2 align-self-center"
                >
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <img
                      src={require(`../assets/plataforms/${item.logoUrl}`)}
                      alt=""
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default AvailableOn;
