export const reviews = [
  {
    content:
      "With its free-floating imagery, ELENA unfolds like a cinematic dream.",
    author: "Stephen Holden - The New York Times"
  },
  {
    content: "The most extraordinary documentary I've seen this decade.",
    author: "Fernando Meirelles - Director of The City Of God"
  },
  {
    content:
      "Filmmaking at its finest. Memory, loss and yearning mingle dreamily in this striking elegy.",
    author: "IndieWire"
  },
  {
    content: "A haunting visual essay.",
    author: "Variety"
  },
  {
    content: "Astounding and lyrical.",
    author: "Filmmaker Magazine"
  },
  {
    content:
      "Petra Costa unfolds a story of grief and thwarted promise with expressive urgency and thoughtful restraint.",
    author: "Richard Brody - The New Yorker"
  },
  {
    content: "A masterful debut.",
    author: "John Anderson - IndieWire"
  },
  {
    content:
      "This mesmerizing portrait, flickering on a screen, immortalizes her even as it's anguished.",
    author: "Alan Scherstuhl - The Village Voice"
  },
  {
    content:
      "A beautiful piece of work; elegant, poetic, visually stunning and viscerally moving. ELENA takes you to a place where you’re reluctant to go as a person. And forces you to face your own demons. A film that made me believe again in the transformative power of cinema and let loose my cynicism about it.",
    author: "Tim Robbins - The Shawshank Redemption"
  },
  {
    content:
      "First, there are three women intermingled. Then, there is search and separation. Elena is now memory. And memory is a living house. ELENA is one of the most beautiful documentaries I have ever seen.",
    author: "Eliane Brum - Brazilian Journalist"
  },
  {
    content:
      "One of the most beautiful Brazilian documentaries in the past few years.",
    author: "Folha de São Paulo"
  },
  {
    content:
      "ELENA is one of the most moving, heartrending cinematic experiences I’ve ever had. Of unusual beauty, it’s a film we keep turning to for a long time. It’s a must see!",
    author: "Walter Salles - Director of The Motorcycle Diaries"
  },
  {
    content:
      "A film I’d like to have done. I got goosebumps several times because it is a film about grief and incomprehension of death. And death should not exist.",
    author: "Ignácio de Loyola Brandão - Brazilian Writer"
  },
  {
    content:
      "ELENA is really a masterpiece in terms of sensibility and communication in the way by which pain can ultimately be transformed by the magic of art into sublimation, compassion and reconciliation.",
    author: "Nicolau Sevcenko - Harvard Professor"
  },
  {
    content:
      "The final impression is that Petra, the director and sister, made and was made by the film, a bit like that image of the hand that draws another hand and is in turn drawn by it. Without the director, Elena would not exist; without ELENA, my impression is that the director would be sadder, her life trapped in unrelenting grief. She would also be spiritually poorer, not having undergone the experience of having made one of the most beautiful films I’ve seen in a long time.",
    author: "João Moreira Salles - Santiago"
  },
  {
    content:
      "Petra manages to tell a personal story with a universal appeal – and she does it by using a collection of extremely moving documentary images (Elena’s dances, her return to Belo Horizonte) alongside original and striking scenes (such as the women floating in their transparent dresses).",
    author: "Zeca Camargo - Brazilian journalist"
  },
  {
    content:
      "ELENA is not a film for the faint of heart. And yet, it is a film about redemption, about full acceptance of the human condition. When the lights came on at the end of the session last night there was absolute silence.",
    author: "Ricardo Gazel - Executive Director of Inhotim"
  },
  {
    content:
      "The first of the many merits of Petra Costa’s film is not having related Elena’s depression—which undoubtedly influenced her final choices—to her talent and sensitivity. The actress is remembered in all her generosity and charisma despite and not because of her problems. ELENA celebrates what comes before and outlives death: the harsh beauty of love which has undergone the hardest of tests.",
    author: "Michel Laub - Brazilian writer"
  }
];

export const availableOn = [
  {
    plataform: "Amazon (DVD)",
    url:
      "https://www.amazon.com/gp/product/B00NB9ILGM/ref=as_li_tl?ie=UTF8&camp=1789&creative=390957&creativeASIN=B00NB9ILGM&linkCode=as2&tag=syndicado-20&linkId=FENIGOUV3DXTKO2S",
    logoUrl: "amazon.png"
  },
  {
    plataform: "Google Play",
    url:
      "https://play.google.com/store/movies/details/Elena?id=1Mh84D5nv1o&hl=en",
    logoUrl: "google-play.png"
  },
  {
    plataform: "iTunes (USA)",
    url:
      "https://itunes.apple.com/us/movie/elena-2012/id879170769?ign-mpt=uo%3D4",
    logoUrl: "itunes.png"
  },
  { plataform: "VHX", url: "http://elena.vhx.tv/", logoUrl: "vhx.png" },
  {
    plataform: "Vimeo",
    url: "https://vimeo.com/ondemand/elena",
    logoUrl: "vimeo.png"
  },
  {
    plataform: "Vudu",
    url: "https://www.vudu.com/content/movies/details/Elena/557123",
    logoUrl: "vudu.png"
  }
];
export const press = [
  {
    url: "http://www.variancefilms.com/elenaassets/Elena_onepgr_V4.pdf",
    title: "One Pager (PDF)"
  },
  {
    url: "http://www.variancefilms.com/elenaassets/Elena_notes.pdf",
    title: "Press Notes (PDF)"
  },
  {
    url: "http://www.variancefilms.com/elenaassets/Elena-Trailer-1080p.mov",
    title: "Trailer Download"
  },
  {
    url: "http://www.variancefilms.com/elenaassets/Elena-Poster-L.jpg",
    title: "Poster Download"
  }
];

export const stills = [
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/01/petra_and_elena_sleeping.png",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/01/petra_and_elena_sleeping_small.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/02/Cena_outdoor_NY.png",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/02/Cena_outdoor_NY_small.png"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/01/poster_water_scene.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/01/poster_water_scene_small.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/01/water_scene_1.png",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/01/water_scene_1_small.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/01/Cena_Petra_NY_perfil_taxi.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/01/Cena_Petra_NY_perfil_taxi_small.jpg"
  },
  {
    large:
      "http://www.en.elenafilme.com/wp-content/themes/elena/static/img/petra-profile.jpg",
    thumbnail:
      "http://www.en.elenafilme.com/wp-content/themes/elena/static/img/landing-page/petra-perfil.jpg"
  },
  {
    large: "http://www.variancefilms.com/elenaassets/Elena14-PetraPhoto.jpg",
    thumbnail:
      "http://www.en.elenafilme.com/wp-content/themes/elena/static/img/landing-page/olhos-petra.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/02/Petra_cena_cereja.png",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/02/Petra_cena_cereja_small.png"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Petra_NY_vidro.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Petra_NY_vidro.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Petra_escada_perfil.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Petra_escada_perfil.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Petra_e_Li_An_perfis.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Petra_e_Li_An_perfis.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Elena_ve_u1_1988.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Elena_ve_u1_1988.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Lian-e-Manoel.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Lian-e-Manoel.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Elena_retrato2_1988.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Elena_retrato2_1988.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Elena_retrato1_1988.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Elena_retrato1_1988.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Elena_retrato_contraste.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Elena_retrato_contraste.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Elena_retrato_1988.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Elena_retrato_1988.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_ELENA_IEMANJA_QUADRADA.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_ELENA_IEMANJA_QUADRADA.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Elena_crianc_a_3x4_1976.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Elena_crianc_a_3x4_1976.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Elena_como_Doralda_Corpo_de_Baile_Boi_Voador_1988_5.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Elena_como_Doralda_Corpo_de_Baile_Boi_Voador_1988_5.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_Elena_como_Doralda_Corpo_de_Baile_Boi_Voador_1988_2.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_Elena_como_Doralda_Corpo_de_Baile_Boi_Voador_1988_2.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_ELENA-NY.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_ELENA-NY.jpg"
  },
  {
    large:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_1988_CORPO_DE_BAILE_SP_MG.jpg",
    thumbnail:
      "http://www.elenafilme.com/wp-content/uploads/2013/05/imprensa_small_1988_CORPO_DE_BAILE_SP_MG.jpg"
  },
  {
    large:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/elena5-1024x573.png",
    thumbnail:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/thumb-elena5.png"
  },
  {
    large: "http://www.en.elenafilme.com/wp-content/uploads/2014/07/elena7.png",
    thumbnail:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/elena7-thumb.png"
  },
  {
    large: "http://www.en.elenafilme.com/wp-content/uploads/2014/07/elena8.png",
    thumbnail:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/elena8-thumb.png"
  },
  {
    large:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/Petra-vermelha.png",
    thumbnail:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/Petra-vermelha-thumb.png"
  },
  {
    large:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/cena-banheira.jpg",
    thumbnail:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/cena-banheira-thumb.jpg"
  },
  {
    large:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/elena-petra.jpg",
    thumbnail:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/elena-petra-thumb.jpg"
  },
  {
    large:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/Petra-barra.jpg",
    thumbnail:
      "http://www.en.elenafilme.com/wp-content/uploads/2014/07/Petra-barra-thumb.jpg"
  }
];

export const partners = [
  {
    logo: "aahn.jpg",
    text:
      "An online resource celebrating the connection between art and healing.",
    url: "http://www.artheals.org/home.html"
  },
  {
    logo: "activeminds.jpg",
    text:
      "Dedicated to raising mental health awareness among college students, on the peer level.",
    url: "http://www.activeminds.org/"
  },
  {
    logo: "brazilfoundation.jpg",
    text:
      "BrazilFoundation is a non-profit established in 2000 with the goal of generating resources for social entrepreneurs who propose creative and innovative solutions to challenges faced by communities throughout Brazil.",
    url: "http://www.brazilfoundation.org/english/"
  },
  {
    logo: "caringhand.jpg",
    text:
      "The mission of ACH is to meet bereaved children and families wherever they are in their grief and fulfill their needs in a caring and knowledgeable environment through services to help them with their emotional journey and financial assistance to aid their future education.",
    url: "http://www.acaringhand.org/"
  },
  {
    logo: "crisis.jpg",
    text:
      "Serves young people in any type of crisis, providing them access to free, 24/7, emotional support and information they need via the medium they already use and trust: text.",
    url: "http://www.crisistextline.org/"
  },
  {
    logo: "davidlynch.jpg",
    text:
      "Healing Traumatic Stress and Raising Performance in At-risk Populations.",
    url: "https://www.davidlynchfoundation.org/"
  },
  {
    logo: "families.jpg",
    text:
      "Families for Depression Awareness helps families recognize and cope with depression and bipolar disorder to get people well and prevent suicides. Visit www.familyaware.org for information about helping someone with depression or bipolar disorder, finding and maintaining wellness, and coping with the loss of a loved one.",
    url: "https://www.davidlynchfoundation.org/"
  },
  {
    logo: "ltt.jpg",
    text:
      "A collection of portraits and stories of suicide attempt survivors, as told by those survivors.",
    url: "http://livethroughthis.org/"
  },
  {
    logo: "mhanyc.jpg",
    text:
      "Founded in 1964, the MHA-NYC has led the way in mental health services, advocacy, and education in the New York metropolitan area and across the nation.",
    url: "http://www.mhaofnyc.org/"
  },
  {
    logo: "nami.jpg",
    text:
      "Provides support, education, and advocacy for families and individuals of all ethnic and socio-economic backgrounds who live with mental illness.",
    url: "http://www.naminycmetro.org/"
  },
  {
    logo: "nwhn.jpg",
    text: "An independent, unbiased voice for women’s healthcare.",
    url: "http://nwhn.org/"
  },
  {
    logo: "nyomh.jpg",
    text:
      "Operates psychiatric centers across the State, and also regulates, certifies and oversees more than 4,500 programs, which are operated by local governments and nonprofit agencies. These programs include various inpatient and outpatient programs, emergency, community support, residential and family care programs.",
    url: "http://www.omh.ny.gov/"
  },
  {
    logo: "samaritans.jpg",
    text:
      "The mission of Samaritans Public Education, Training and Awareness Program is to provide lay and professional caregivers and health providers with the skills, tools, information, resources and awareness they need to increase their comfort, confidence and competency in effectively identifying, responding to, caring for and treating those individuals who are in distress, depressed, in crisis or suicidal.",
    url: "http://samaritansnyc.org/"
  }
];
