import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import logo from "../assets/logo-elena.png";
import laurels1 from "../assets/laurels-1.png";
import laurels2 from "../assets/laurels-2.png";
import videobg from "../assets/site_home_v2_1280x720.mp4";

class Hero extends Component {
  state = {
    laurel: false,
    showTrailer: false
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location !== this.props.location &&
      this.props.location.hash === "#trailer" &&
      this.state.showTrailer === false
    ) {
      this.setState({ showTrailer: true });
    }
  }
  componentDidMount() {
    this.changeLaurel();
  }

  changeLaurel() {
    setInterval(() => {
      let laurel = !this.state.laurel;
      this.setState({ laurel });
    }, 6000);
  }
  handleShowTrailer = () => {
    const showTrailer = !this.state.showTrailer;
    this.setState({ showTrailer });
  };
  render() {
    return (
      <header className="hero">
        <img
          className={this.state.laurel ? "laurels fadeIn" : "laurels fadeOut"}
          src={laurels1}
          alt=""
        />
        <img
          className={this.state.laurel ? "laurels fadeOut" : "laurels fadeIn"}
          src={laurels2}
          alt=""
        />
        <div className="hero_meta">
          <img className="logo" src={logo} alt="" />
          <p>
            Based on a true story, ELENA obliterates the line between
            documentary, diary and fever dream, and is at once captivating and
            devastating.
          </p>
          <p className="watch_trailer" onClick={this.handleShowTrailer}>
            Watch Trailer <i className="fas fa-play-circle ml-2" />
          </p>
        </div>
        <div className="shadow" />
        <video className="hero_video" src={videobg} autoPlay muted loop />
        {this.state.showTrailer ? (
          <div className="trailer">
            <span className="trailer_close" onClick={this.handleShowTrailer}>
              Close Trailer <i className="ml-2 fas fa-times" />
            </span>
            <div className="trailer_wrapper">
              <iframe
                src="https://www.youtube.com/embed/y5hTIOCuwy4"
                frameborder="0"
                allowFullScreen
                title="trailer"
              />
            </div>
            <div className="trailer_shadow" onClick={this.handleShowTrailer} />
          </div>
        ) : (
          ""
        )}
      </header>
    );
  }
}

export default withRouter(Hero);
