import React, { Component } from "react";
import axios from "axios";
import PaginationNumbers from "./paginationNumbers";
import close from "../assets/close.png";
const baseUrl = "http://en.elenafilme.com/wp-json/wp/v2/posts";
class Blog extends Component {
  state = {
    posts: [{ title: { rendered: "" }, excerpt: { rendered: "" } }],
    post: { title: { rendered: "" }, content: { rendered: "" } },
    totalPages: 0,
    currentPage: 1,
    showPost: false,
    loading: true
  };
  getContent = async () => {
    const getPosts = await axios.get(
      `${baseUrl}?page=${this.state.currentPage}&per_page=5`
    );
    const posts = await getPosts.data;
    const totalPages = parseInt(await getPosts.headers["x-wp-totalpages"]);
    console.log(totalPages);
    this.setState({ posts, totalPages, loading: false });
  };
  getSearch = async s => {
    const getPosts = await axios.get(`${baseUrl}?search=${s}&per_page=5`);
    const posts = await getPosts.data;
    const totalPages = parseInt(await getPosts.headers["x-wp-totalpages"]);
    console.log(totalPages);
    this.setState({ posts, totalPages, loading: false });
  };
  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.getContent();
    });
  }
  _handlePageChange = currentPage => {
    this.setState({ currentPage, loading: true }, () => {
      this.getContent();
    });
  };
  _handleOpenPost = post => {
    const showPost = true;
    this.setState({ post, showPost });
  };
  _handleClosePost = () => {
    const showPost = false;
    this.setState({ showPost });
  };
  _handleSearch = e => {
    e.preventDefault();
    const s = e.currentTarget.search.value;
    this.setState({ loading: true }, () => {
      this.getSearch(s);
    });
  };
  render() {
    return (
      <div id="blog" className="blog">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-lg-2">
              <div className="sticky-top">
                <h2 className="secondary_title">Blog</h2>
                <form className="blog_search" onSubmit={this._handleSearch}>
                  <div className="form-group">
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      name="search"
                      placeholder="Search..."
                    />
                  </div>

                  <button className="btn btn-primary btn-sm" submit>
                    Search
                  </button>
                </form>
              </div>
            </div>
            <div className="posts_container col-sm-9 col-lg-10">
              {this.state.loading ? (
                <div className="loading_posts">
                  <div className="spinner">
                    <div className="loader" />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                {this.state.posts.map(post => {
                  const date = new Date(post.date);
                  const dateOptions = {
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  };
                  return (
                    <div className="col-md-12 post">
                      <h3 onClick={() => this._handleOpenPost(post)}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: post.title.rendered
                          }}
                        />{" "}
                        <small className="post_date">
                          / {date.toLocaleDateString("pt-BR", dateOptions)}
                        </small>
                      </h3>
                      <small
                        dangerouslySetInnerHTML={{
                          __html: post.excerpt.rendered
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <PaginationNumbers
                totalPages={this.state.totalPages}
                currentPage={this.state.currentPage}
                onPageChange={this._handlePageChange}
              />
            </div>
          </div>
        </div>
        {this.state.showPost ? (
          <div className="currentPost">
            <div className="post_wrapper">
              <div className="post_header">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: this.state.post.title.rendered
                  }}
                />
                <div className="post_close" onClick={this._handleClosePost}>
                  <img src={close} alt="" />
                </div>
              </div>
              <div
                className="post_content"
                dangerouslySetInnerHTML={{
                  __html: this.state.post.content.rendered
                }}
              />
            </div>
            <div className="post_bg" onClick={this._handleClosePost} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Blog;
