import React, { Component } from "react";
import { reviews as reviewsArray } from "../data/data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

class Reviews extends Component {
  state = {};

  render() {
    const settings = {
      className: "",
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      swapToSlide: true
    };
    const reviews = reviewsArray;
    return (
      <div id="reviews" className="reviews">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-lg-2">
              <h2 className="secondary_title sticky-top">
                Reactions and Reviews
              </h2>
            </div>
            <div className="col-sm-9 col-lg-10 text-center mb-5">
              <Slider {...settings}>
                {reviews.map(review => {
                  return (
                    <div key={review.author} className="reviews_review">
                      <p>{review.content}</p>
                      <h4>{review.author}</h4>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
          <hr />
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h3>Fernando Meirelles and Tim Robbins on Elena</h3>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="FernandoMeirellesandTimRobbins"
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/qk-fZf_KfNc?rel=0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Reviews;
