import React, { Component } from "react";

class Crew extends Component {
  state = {};
  render() {
    const cast = (
      <p>
        <strong>Director</strong> - Petra Costa
        <br />
        <strong>Screenplay</strong> - Petra Costa and Carolina Ziskind
        <br />
        <strong>Cast</strong> - Elena Andrade, Li An and Petra Costa
        <br />
        <strong>Production Company</strong> - Busca Vida Films
        <br />
        <strong>Executive Producers</strong> – Tim Robbins and Fernando
        Meirelles
        <br />
        <strong>Associate Producers</strong> - Tiago Pavan, Moara Passoni, Sara
        Dosa, Stefan Davi
        <br />
        <strong>Supervising Producers</strong> – Julia Bock and Daniela Santos
        <br />
        <strong>Producer in New York</strong> - Caroline Onikute
        <br />
        <strong>Production Coordinator</strong> - Vanessa Elias
        <br />
        <strong>Producer (Release)</strong> - Bernardo Bath
        <br />
        <strong>Executive Production Assistant</strong> - Isadora Ferreira
        <br />
        <strong>Photography</strong> - Janice D’avila, Will Etchebehere and
        Miguel Vassy
        <br />
        <strong>Art Directors</strong> - Martha Kiss Perrone, Alonso Pafyese and
        Lorena Ortiz
        <br />
        <strong>Film Editing</strong> - Marilia Moraes and Tina Baz
        <br />
        <strong>First Editing</strong> - Idê Lacreta
        <br />
        <strong>Additional Editing and Direction Assistant</strong> - Virginia
        Primo
        <br />
        <strong>Sound Designer</strong> - Olivier Goinard And Guile Martins
        <br />
        <strong>Sound Mixer</strong> - Olivier Goinard
        <br />
        <strong>Sound Recordist</strong> - Edson Secco
        <br />
        <strong>Soundtrack Supervision</strong> - Fil Pinheiro
        <br />
        <strong>Original Soundtrack</strong> - Vitor Araújo, Fil Pinheiro,
        Maggie Hastings Clifford and Gustavo Ruiz
        <br />
        <strong>Post-Production Coordinator</strong> - Laura Futuro
        <br />
        <strong>Post-Production Assistant</strong> - Fabio De Borthole
        <br />
        <strong>Editing Room Assistant</strong> - Andre Gustavo Requião
        <br />
        <strong>Screenplay Consultant</strong> - Daniela Capelato
        <br />
        <strong>Screenplay Doctoring</strong> - Aleksei Abib
        <br />
        <strong>Acting Coach</strong> - Martha Kiss Perrone
        <br />
        <strong>Editing Consultant</strong> - Xavier Box
        <br />
        <strong>Translation</strong> - Robin Geld
        <br />
        <strong>New York Team</strong> - Sofia Geld, Patrícia Saltara, Daniel
        Varotto, Muhammed Hamdy, Kristin McCracken, Mikaela Beardsley
      </p>
    );
    const production = (
      <p>
        <strong>PRODUCTION COMPANY</strong>
        <br />
        Busca Vida Filmes co-produced the short Olhos de Ressaca (Undertow Eyes)
        and produced the feature ELENA, both by director Petra Costa. It is
        currently co-producing the documentaries Lira Paulistana and Vanguarda
        Paulista by Riba de Castro, and Orestes by Rodrigo Siqueira, and the
        play Rózà by Martha Kiss Perrone and Joana Levi.
      </p>
    );
    return (
      <div id="crew" className="crew">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-lg-2">
              <h2 className="secondary_title sticky-top">Cast and Crew</h2>
            </div>
            <div className="col-sm-9 col-lg-10">
              {cast}
              {production}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Crew;
