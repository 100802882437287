import React, { Component } from "react";
import ptBr from "../assets/pt-br.png";

class Nav extends Component {
  state = {
    showMenu: false
  };
  _handleShow = () => {
    const showMenu = !this.state.showMenu;
    this.setState({ showMenu });
  };
  render() {
    return (
      <React.Fragment>
        <div className="nav">
          <button
            className="btn btn-outline-light"
            onClick={() => this._handleShow()}
          >
            <i className="fas fa-bars" />
          </button>
          <ul className={this.state.showMenu ? "show" : "hide"}>
            <li>
              <a href="#trailer" onClick={this._handleShow}>
                Trailer
              </a>
            </li>
            <li>
              <a href="#availableOn" onClick={this._handleShow}>
                Watch
              </a>
            </li>
            <li>
              <a href="#synopsis" onClick={this._handleShow}>
                Synopsis
              </a>
            </li>
            <li>
              <a href="#reviews" onClick={this._handleShow}>
                Reviews
              </a>
            </li>
            <li>
              <a href="#press" onClick={this._handleShow}>
                Press
              </a>
            </li>
            <li>
              <a href="#crew" onClick={this._handleShow}>
                Cast and Crew
              </a>
            </li>
            <li>
              <a href="#director" onClick={this._handleShow}>
                Director
              </a>
            </li>
            <li>
              <a href="#blog" onClick={this._handleShow}>
                Blog
              </a>
            </li>
            <li>
              <a href="#movement" onClick={this._handleShow}>
                Movement
              </a>
            </li>
            <li>
              <a href="#contact" onClick={this._handleShow}>
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="pt_br">
          <a
            href="http://www.elenafilme.com/?redir=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ptBr} alt="" />
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default Nav;
