import React from "react";

const Insights = () => {
  return (
    <div className="insights">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h4>Share your e-motions</h4>
            <p>
              <small>
                After watching ELENA, the Brazilian director Fernando Meirelles
                (City of God) said: "ELENA is a rare cinematic experience. A
                film that generates 60 insights a minute." Insight is an
                intuition, a revelation, something that emerges as a new and
                imperative idea. When an insight goes through a creative
                process, it becomes a work of art. In this page, we will post
                poems, photos, drawings, videos, songs and all types of insights
                that the film has generated. Send your piece or your thoughts to
                <strong> contato@elenafilme.com</strong>
              </small>
            </p>
            <a
              href="https://www.youtube.com/watch?v=ccx3n5lduV8&list=PLHiT7Ws2D3PvDrfcjt8iv5fym828p8RfM"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5>
                <span>
                  Watch <strong>insights</strong> on Elena
                </span>
              </h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insights;
