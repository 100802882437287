import React, { Component } from "react";
import { press, stills } from "../data/data";

class Press extends Component {
  state = {};
  render() {
    return (
      <div id="press" className="press">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-lg-2">
              <h2 className="secondary_title sticky-top">Press</h2>
            </div>
            <div className="col-sm-9 col-lg-10">
              <div className="row">
                {press.map(item => {
                  return (
                    <div key={item.url} className="col-auto">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h4>{item.title}</h4>
                      </a>
                    </div>
                  );
                })}
              </div>
              <div className="row stillsrow">
                {stills.map(still => {
                  return (
                    <div key={still.large} className="col-4 col-md-2 still">
                      <a
                        href={still.large}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="shadow" />
                        <img src={still.thumbnail} alt="" className="still" />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Press;
