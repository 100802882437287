import React, { Component } from "react";

class Contact extends Component {
  state = {};
  render() {
    return (
      <div id="contact" className="contact">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-lg-2">
              <h2 className="secondary_title sticky-top">Contact</h2>
            </div>
            <div className="col-sm-9 col-lg-10">
              <p>
                Address: R. Alberto Seabra, 1175, <br />
                CEP 05452-001 São Paulo-SP, Brazil
                <br />
                Phone: +55 11 2373 7488
                <br />
                Email: contato@elenafilme.com
              </p>
            </div>
          </div>
        </div>
        <div className="credits text-center">
          <small>
            Developed by{" "}
            <a
              href="https://viniciusofp.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>viniciusofp</strong> | 2019
            </a>
          </small>
        </div>
      </div>
    );
  }
}

export default Contact;
